import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
	selector: 'app-forbidden',
	templateUrl: './forbidden.component.html',
	styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {
	constructor(private msalService: MsalService) { }

	ngOnInit() {
	}

	onSignOut() {
		this.msalService.logout();
	}
}
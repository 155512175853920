import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';

@Injectable({
	providedIn: 'root'
})
export class MaintenanceService {
	constructor(private httpClient: HttpClient) { }

	public getMaintenanceStatus() { //: Promise<boolean> {
		console.log('in get Maintenance status');
		return this.httpClient.get<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.portalMaintenance);
		// let maintenanceStatus: boolean;
		// return new Promise((resolve, _reject) => {
		// 	this.httpClient.get<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.portalMaintenance).subscribe((response: boolean) => {	
		// 		maintenanceStatus = response;
		// 	});	
		// 	resolve(maintenanceStatus);
		// });
	}
}
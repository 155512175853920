import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { SqlInstanceConfiguration } from '../models/sql-instance-configuration';
import { HostSystemInformation } from '../models/host-system-information';
import { DriveConfiguration } from '../models/drive-configuration';
import { Instance } from '../models/instance';
import { Activity } from '../models/activity';
import { InstanceStatus } from '../models/instance-status';
import { SqlInstanceConfigHistoryItem } from '../models/sql-instance-config-history-item';
import { LookupItem } from '../models/lookupItem';
import { SqlAlert } from '../models/sql-alert';
import { UptimeSummary } from '../models/uptime-summary';
import { RestartHistoryItem } from '../models/restart-history-item';
import { PerformanceDataTypeEnum } from '../models/enums/performance-data-type-enum';
import { SqlAgentFailedJob } from '../models/sql-agent-failed-job';
import { DatabaseSummary } from '../models/database-summary';
import { Certificate } from '../models/certificate';
import { LoginFailure } from '../models/login-failure';
import { SystemAdministrator } from '../models/system-administrator';
import { DriveSpaceHistoryData } from '../models/drive-space-history-data';
import { Drive } from '../models/drive';
import { SqlQuerySortTypeEnum } from '../models/enums/sql-query-sort-type-enum';
import { DriveSqlFiles } from '../models/drive-sql-files';
import { map } from 'rxjs/operators';
import { InstanceConnectionString } from '../models/instance-connection-string';
import { LongRunningQuerySortTypeEnum } from '../models/enums/long-running-query-sort-type-enum';
import { LongRunningQueryPerformanceItem } from '../models/long-running-query-performance-item';
import { HADRInfo } from '../models/hadr-info';
import { InsecureLogin } from '../models/insecure-login';
import { LinkedServer } from '../models/linked-server';
import { AppConfig } from 'src/app/app.config';
import { LastLogin } from '../models/last-login';
import { SqlErrorLogSortTypeEnum } from '../models/enums/sql-errorlog-sort-type-enum';
import { DataClassification } from '../models/data-classification';
import { DateTime } from 'luxon';
import { MissingIndexSummary } from '../models/missing-index-summary';
import { MissingIndexDetail } from '../models/missing-index-detail';
import { of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class InstanceService {

	constructor(private httpClient: HttpClient) { }

	public getInstance(instanceId: number) {
		return this.httpClient.get<Instance>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.instance + '/' + instanceId);
	}

	public getInstances(clientId: number, hostId: number, activeOnly = false, tagIds: number[]) {
		let params = new HttpParams().set('clientId', clientId === 0 ? '' : clientId.toString());
		params = params.append('hostId', hostId === 0 ? '' : hostId.toString());

		if (tagIds[0] !== null) {
			for (const tagId of tagIds) {
				params = params.append('tagIds', tagId.toString());
			}
		}

		return this.httpClient.get<Instance[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.instance, {params})
			.pipe(
				map(items => {
					return activeOnly ? items.filter(item => item.isActive === true) : items;
				})
			);
	}

	public getDrives(instanceId: number) {
		if(instanceId == 0){
			return of(new Array<Drive>());
		}
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<Drive[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.drive, {params});
	}

	public addInstance(instance: Instance) {
		return this.httpClient.post<Instance>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.instance, instance);
	}

	public updateInstance(instanceId: number, instance: Instance) {
		const patchData = [
			{
				op: 'replace',
				path: '/InstanceName',
				value: instance.instanceName
			},
			{
				op: 'replace',
				path: '/SLALevelID',
				value: instance.slaLevelID
			},
			{
				op: 'replace',
				path: '/HasPerfModule',
				value: instance.hasPerfModule
			},
			{
				op: 'replace',
				path: '/HasHADRModule',
				value: instance.hasHADRModule
			},
			{
				op: 'replace',
				path: '/HasAssistModule',
				value: instance.hasAssistModule
			},
			{
				op: 'replace',
				path: '/HasAssureModule',
				value: instance.hasAssureModule
			},
			{
				op: 'replace',
				path: '/HasLS',
				value: instance.hasLS
			},
			{
				op: 'replace',
				path: '/HasM',
				value: instance.hasM
			},
			{
				op: 'replace',
				path: '/HasFC',
				value: instance.hasFC
			},
			{
				op: 'replace',
				path: '/HasAG',
				value: instance.hasAG
			},
			{
				op: 'replace',
				path: '/AliveThresholdMins',
				value: instance.aliveThresholdMins
			},
			{
				op: 'replace',
				path: '/PatchGroupId',
				value: instance.patchGroupId
			},
			{
				op: 'replace',
				path: '/IsActive',
				value: instance.isActive
			},
			{
				op: 'replace',
				path: '/Applications',
				value: instance.applications
			}
		];

		return this.httpClient.patch<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.instance + '/' + instanceId, patchData);
	}

	public getInstanceConnectionString(instanceId: number) {
		return this.httpClient.get<InstanceConnectionString>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.instanceConnectionString + '/' + instanceId);
	}

	public updateInstanceConnectionString(instanceId: number, instanceConnectionString: InstanceConnectionString) {
		const patchData = [
			{
				op: 'replace',
				path: '/ConnectionString',
				value: instanceConnectionString.connectionString
			}
		];

		return this.httpClient.patch<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.instanceConnectionString + '/' + instanceId, patchData);
	}

	public getSqlInstanceConfiguration(instanceId: number) {
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<SqlInstanceConfiguration>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.sqlInstanceConfiguration, {params});
	}

	public getHostSystemInformation(serviceType: number, instanceId: number) {
		let params = new HttpParams().set('serviceType', serviceType.toString());
		params = params.append('instanceId', instanceId.toString());
		return this.httpClient.get<HostSystemInformation>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.hostSystemInformation, {params});
	}

	public getHADRInfo(instanceId: number) {
		return this.httpClient.get<HADRInfo>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.hadrInfo + '/' + instanceId.toString());
	}

	public getDriveConfiguration(instanceId: number) {
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<DriveConfiguration[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.driveConfiguration, {params});
	}

	public searchDriveSpaceHistory(instanceId: number, startDate: DateTime, endDate: DateTime, driveIds: number[]) {		
		if (driveIds && driveIds.length === 1 && driveIds[0] === 0) {
			driveIds === null;
		}

		let params = new HttpParams().set('instanceId', instanceId.toString());
		params = params.append('startDateTime',	startDate.toUTC().toString());
		params = params.append('endDateTime', endDate.toUTC().toString());
		if (driveIds !== null) {
			for (const driveId of driveIds) {
				params = params.append('driveIds', driveId.toString());
			}
		}

		return this.httpClient.get<DriveSpaceHistoryData[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.driveSpaceHistory, {params});
	}

	public searchActivityStream(instanceId: number, startDate: DateTime, endDate: DateTime, activityTypeIds: string[]) {

		let params = new HttpParams()
			.set('instanceId', instanceId.toString());

		if (startDate !== null) {
			startDate.startOf('day');
			params = params.append('startDateTime', startDate.toUTC().toString());
		}

		if (endDate !== null) {
			endDate.endOf('day');
			params = params.append('endDateTime', endDate.toUTC().toString());
		}

		if (activityTypeIds != null) {
			for (const activityTypeId of activityTypeIds) {
				params = params.append('activityTypeIds', activityTypeId);
			}
		}

		return this.httpClient.get<Activity[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.activity, {params});
	}

	public getStatus(instanceId: number) {
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<InstanceStatus[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.instanceStatus, {params});
	}

	public searchSqlConfigHistory(instanceId: number, startDate: DateTime, endDate: DateTime, configName: string) {
		let params = new HttpParams()
			.set('instanceId', instanceId.toString());

		if (startDate !== null) {
			startDate.startOf('day');
			params = params.append('startDateTime', startDate.toUTC().toString());
		}

		if (endDate !== null) {
			endDate.endOf('day');
			params = params.append('endDateTime', endDate.toUTC().toString());
		}

		if (configName !== null) {
			params = params.append('configName', configName);
		}

		return this.httpClient.get<SqlInstanceConfigHistoryItem[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.sqlInstanceConfigHistory, {params});
	}

	public getSqlInstanceConfigTypes(instanceId: number) {
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<LookupItem[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.sqlInstanceConfigurationType, {params});
	}

	public searchSqlAlert(instanceId: number, startDate: DateTime, endDate: DateTime, sqlMessageTypeId: number, numberOfQueries: number, SortTypeEnum: SqlErrorLogSortTypeEnum) {

		let params = new HttpParams()
			.set('instanceId', instanceId.toString());

		if (startDate !== null) {
			startDate.startOf('day');
			params = params.append('startDateTime', startDate.toUTC().toString());
		}

		if (endDate !== null) {
			endDate.endOf('day');
			params = params.append('endDateTime', endDate.toUTC().toString());
		}

		if (sqlMessageTypeId !== null) {
			params = params.append('sqlMessageTypeId', sqlMessageTypeId.toString());
		}

		params = params.append('numberOfQueries', numberOfQueries.toString());
		params = params.append('sortTypeEnum', SortTypeEnum.toString());

		return this.httpClient.get<SqlAlert[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.sqlAlert, {params});
	}

	public searchUptimeSummary(instanceId: number, startDate: DateTime, endDate: DateTime) {
		let params = new HttpParams().set('instanceId', instanceId.toString());
		if (startDate !== null) {
			startDate.startOf('day');
			params = params.append('startDateTime', startDate.toUTC().toString());
		}
		if (endDate !== null) {
			endDate.endOf('day');
			params = params.append('endDateTime', endDate.toUTC().toString());
		}
		return this.httpClient.get<UptimeSummary>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.uptimeSummary, {params});
	}

	public searchRestartHistory(instanceId: number, startDate: DateTime, endDate: DateTime) {

		let params = new HttpParams()
			.set('instanceId', instanceId.toString());

		if (startDate !== null) {
			startDate.startOf('day');
			params = params.append('startDateTime',	startDate.toUTC().toString());
		}

		if (endDate !== null) {
			endDate.endOf('day');
			params = params.append('endDateTime', endDate.toUTC().toString());
		}

		return this.httpClient.get<RestartHistoryItem[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.restartHistory, {params});
	}

	public searchPerformanceData(performanceDataTypeEnum: PerformanceDataTypeEnum, instanceId: number, startDateTime: DateTime, endDateTime: DateTime) {
		let params = new HttpParams().set('instanceId', instanceId.toString());

		if (startDateTime !== null) {
			params = params.append('startDateTime', startDateTime.toUTC().toString());
		}

		if (endDateTime !== null) {
			params = params.append('endDateTime', endDateTime.toUTC().toString());
		}

		params = params.append('performanceDataType', performanceDataTypeEnum.toString());
		return this.httpClient.get<any[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.instancePerformance, {params});
	}

	public searchSqlQueries(sqlQuerySortTypeEnum: SqlQuerySortTypeEnum, instanceId: number, startDateTime: DateTime, endDateTime: DateTime, numberOfQueries: number, removeMaintenance: boolean , databaseIds: number[]) {

		let params = new HttpParams()
			.set('instanceId', instanceId.toString());

		if (startDateTime !== null) {
			params = params.append('startDateTime', startDateTime.toUTC().toString());
		}

		if (endDateTime !== null) {
			params = params.append('endDateTime', endDateTime.toUTC().toString());
		}

		if (numberOfQueries !== null) {
			params = params.append('numberOfQueries', numberOfQueries.toString());
		}
		params = params.append('sqlQuerySortTypeEnum', sqlQuerySortTypeEnum.toString());
		params = params.append('removeMaintenance', removeMaintenance.toString());

		if (databaseIds != null) {
			for (const databaseId of databaseIds) {
				params = params.append('databaseIds', databaseId.toString());
			}
		}
		return this.httpClient.get<any[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.sqlQuery, {params});
	}

	public searchLongRunningQueriesUI(
		longRunningQuerySortTypeEnum: LongRunningQuerySortTypeEnum, instanceId: number, startDateTime: DateTime,
		endDateTime: DateTime, numberOfQueries: number,
		removeMaintenance: boolean,
		// maintenanceOnly: boolean,
		databaseIds: number[])
	{
		let params = new HttpParams().set('instanceId', instanceId.toString());

		if (startDateTime !== null) {
			params = params.append('startDateTime', startDateTime.toUTC().toString());
		}

		if (endDateTime !== null) {
			params = params.append('endDateTime', endDateTime.toUTC().toString());
		}

		// if (numberOfQueries !== null) {
		params = params.append('numberOfQueries', numberOfQueries.toString());
		// }

		params = params.append('longRunningQuerySortTypeEnum', longRunningQuerySortTypeEnum.toString());
		params = params.append('removeMaintenance', removeMaintenance.toString());
		// params = params.append('maintenanceOnly', maintenanceOnly.toString());

		if (databaseIds !== null) {
			for (const databaseId of databaseIds) {
				params = params.append('databaseIds', databaseId.toString());
			}
		}

		// params = params.append('filterByBusinessHours', filterByBusinessHours.toString());
		// params = params.append('businessHours', businessHours.toString());
		// if (businessDays !== (null || undefined)) {
		// 	for (const businessDay of businessDays) {
		// 		params = params.append('businessDays', businessDay.toString());
		// 	}
		// }
		// params = params.append('businessStartHour', businessStartHour.toString());
		// params = params.append('businessStartMinute', businessStartMinute.toString());
		// params = params.append('businessEndHour', businessEndHour.toString());
		// params = params.append('businessEndMinute', businessEndMinute.toString());

		return this.httpClient.get<LongRunningQueryPerformanceItem[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.longRunningQuery + '/searchUI', {params});
	}

	public searchLongRunningQueriesReport(
		longRunningQuerySortTypeEnum: LongRunningQuerySortTypeEnum, instanceId: number, startDateTime: DateTime,
		endDateTime: DateTime, numberOfQueries: number,
		removeMaintenance: boolean,
		// maintenanceOnly: boolean,
		databaseIds: number[],
		filterByBusinessHours: boolean,
		businessHours: boolean,
		businessDays: number[],
		businessStartHour: number,
		businessStartMinute: number,
		businessEndHour: number,
		businessEndMinute: number) {

		let params = new HttpParams().set('instanceId', instanceId.toString());

		if (startDateTime !== null) {
			params = params.append('startDateTime', startDateTime.toUTC().toString());
		}

		if (endDateTime !== null) {
			params = params.append('endDateTime', endDateTime.toUTC().toString());
		}

		if (numberOfQueries !== null) {
			params = params.append('numberOfQueries', numberOfQueries.toString());
		}
		params = params.append('longRunningQuerySortTypeEnum', longRunningQuerySortTypeEnum.toString());
		params = params.append('removeMaintenance', removeMaintenance.toString());
		// params = params.append('maintenanceOnly', maintenanceOnly.toString());

		if (databaseIds !== null) {
			for (const databaseId of databaseIds) {
				params = params.append('databaseIds', databaseId.toString());
			}
		}

		params = params.append('filterByBusinessHours', filterByBusinessHours.toString());
		params = params.append('businessHours', businessHours.toString());
		if (businessDays !== null) {
			for (const businessDay of businessDays) {
				params = params.append('businessDays', businessDay.toString());
			}
		}
		params = params.append('businessStartHour', businessStartHour.toString());
		params = params.append('businessStartMinute', businessStartMinute.toString());
		params = params.append('businessEndHour', businessEndHour.toString());
		params = params.append('businessEndMinute', businessEndMinute.toString());
		return this.httpClient.get<LongRunningQueryPerformanceItem[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.longRunningQuery + '/searchReport', {params});
	}

	public searchSqlAgentFailedJobs(instanceId: number, startDate: DateTime, endDate: DateTime) {
		let params = new HttpParams()
			.set('instanceId', instanceId.toString());

		if (startDate !== null) {
			startDate.startOf('day');
			params = params.append('startDateTime', startDate.toUTC().toString());
		}

		if (endDate !== null) {
			endDate.endOf('day');
			params = params.append('endDateTime', endDate.toUTC().toString());
		}

		return this.httpClient.get<SqlAgentFailedJob[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.sqlAgentFailedJob, {params});
	}

	public getDatabaseSummaries(instanceId: number) {
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<DatabaseSummary[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.databaseSummary, {params});
	}

	public searchDataClassifications(instanceId: number) {
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<DataClassification[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.dataClassification, {params});
	}

	public searchCertificates(instanceId: number) {
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<Certificate[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.certificate, {params});
	}

	public searchLoginFailures(instanceId: number, startDate: DateTime, endDate: DateTime) {
		let params = new HttpParams().set('instanceId', instanceId.toString());

		if (startDate !== null) {
			startDate.startOf('day');
			params = params.append('startDateTime', startDate.toUTC().toString());
		}

		if (endDate !== null) {
			endDate.endOf('day');
			params = params.append('endDateTime', endDate.toUTC().toString());
		}

		return this.httpClient.get<LoginFailure[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.loginFailure, {params});
	}

	public searchInsecureLogins(instanceId: number) {
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<InsecureLogin[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.insecureLogin, {params});
	}

	public searchSystemAdministrators(instanceId: number, startDate: DateTime, endDate: DateTime) {
		let params = new HttpParams().set('instanceId', instanceId.toString());

		if (startDate !== null) {
			startDate.startOf('day');
			params = params.append('startDateTime', startDate.toUTC().toString());
		}

		if (endDate !== null) {
			endDate.endOf('day');
			params = params.append('endDateTime', endDate.toUTC().toString());
		}

		return this.httpClient.get<SystemAdministrator[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.systemAdministrator, {params});
	}

	public searchLinkedServers(instanceId: number){
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<LinkedServer[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.linkedServer, {params});
	}

	public generatePerformanceReportPdf(instanceId: number, url: string) {
		const params = new HttpParams()
			.set('instanceId', instanceId.toString())
			.set('url', url);
		return this.httpClient.get<Blob>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.performanceReport, { params, responseType: 'blob' as 'json', observe: 'body' });
	}

	public searchDriveSqlFiles(instanceId: number, startDate: DateTime, endDate: DateTime) {
		let params = new HttpParams().set('instanceId', instanceId.toString());

		if (startDate !== null) {
			startDate.startOf('day');
			params = params.append('startDateTime', startDate.toUTC().toString());
		}

		if (endDate !== null) {
			endDate.endOf('day');
			params = params.append('endDateTime', endDate.toUTC().toString());
		}

		return this.httpClient.get<DriveSqlFiles[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.driveSqlFiles, {params});
	}

	public searchLastLogins(instanceId: number) {
		console.log('searchLastLogins');
		console.log(instanceId);
		const params = new HttpParams().set('instanceId', instanceId.toString());
		return this.httpClient.get<LastLogin[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.lastLogin, {params});
	}

	public getMissingIndexSummaries(instanceId: number, databaseIds: number[]) {
		let params = new HttpParams().set('instanceId', instanceId.toString());

		if (databaseIds !== null) {
			for (const databaseId of databaseIds) {
				params = params.append('databaseIds', databaseId.toString());
			}
		}

		return this.httpClient.get<MissingIndexSummary[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.missingIndexes + '/GetMissingIndexSummaries', {params});
	}

	public getMissingIndexDetails(instanceId: number, databaseIds: number[]) {
		let params = new HttpParams().set('instanceId', instanceId.toString());

		if (databaseIds !== null) {
			for (const databaseId of databaseIds) {
				params = params.append('databaseIds', databaseId.toString());
			}
		}

		return this.httpClient.get<MissingIndexDetail[]>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.missingIndexes + '/GetMissingIndexDetails', {params});
	}
}

<h2 mat-dialog-title> Portal preferences </h2>

<mat-dialog-content [formGroup]="editForm">
	<mat-form-field>
		<mat-label>Pagination preference</mat-label>
		<mat-select formControlName="pagination">
			<mat-option *ngFor="let option of pageSizeOptions" [value]="option.valueOf()">
				{{option.valueOf()}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
	<button class="mat-mdc-raised-button" (click)="onCancel()">Cancel</button>
	<button class="mat-mdc-raised-button mat-mdc-primary" (click)="onSave()">Save</button>
</mat-dialog-actions>

import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Component({
	selector: 'app-report-container',
	templateUrl: './report-container.component.html',
	styleUrls: ['./report-container.component.css']
})
export class ReportContainerComponent implements OnInit {
	environment = AppConfig.settings?.name;
	constructor() { }

	ngOnInit() {
	}
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
type NewType = string;
@Component({
	selector: 'app-info-snack-bar',
	templateUrl: './info-snack-bar.component.html',
	styleUrls: ['./info-snack-bar.component.css']
})
export class InfoSnackBarComponent implements OnInit {

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NewType) { }

	ngOnInit() {
	}

}

<div class="container">
	<mat-card appearance="outlined" class="card-container">
		<mat-card-header>
			<div mat-card-avatar><mat-icon color="warn">lock</mat-icon></div>				 
			<mat-card-title>403 PAGE FORBIDDEN</mat-card-title>					
		</mat-card-header>
		
		<mat-card-content>
			<p>We're sorry, you don't have access to the page you requested. Please go back to the home page.</p>
			<p>To view this page, you may have to sign in with a different account</p>
		</mat-card-content>
		<mat-card-actions>
			<button class="mat-mdc-raised-button mat-mdc-primary" (click)="onSignOut()">Sign in with a different account</button>
		</mat-card-actions>
	</mat-card>
</div>
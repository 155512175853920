import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { MsalService } from '@azure/msal-angular';
import { AuthError } from '@azure/msal-browser';
import { from } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';

@Injectable()
export class MsalInterceptor implements HttpInterceptor {
	constructor(
		public msalService: MsalService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let token: string;
		// Acquire a token for this request, and attach as proper auth header.
		return from(
			this.msalService.instance.acquireTokenSilent({scopes: ['https://datasentinelportal.onmicrosoft.com/' + AppConfig.settings?.authenticationconfig.krakenClientId + '/access_as_user']})
			// this.msalService.instance.acquireTokenSilent({scopes: ['api://' + AppConfig.settings?.authenticationconfig.krakenClientId + '/access_as_user']})
				.then((response): HttpRequest<any> => {
					token = response.accessToken;
					const authHeader = `Bearer ${token}`;
					// console.log('authHeader: ' + authHeader);
					return request.clone({
						setHeaders: {
							Authorization: authHeader,
						}
					});
				})
		).pipe(
			mergeMap(nextReq => next.handle(nextReq)),
			tap(
				() => {},
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				err => {
					const error = err as AuthError;
					// chrome private tab fallback => if acquireTokenSilent fails, we try acquireTokenRedirect method
					if(error.errorMessage && error.errorMessage.includes('AADB2C90077')) {
						return this.msalService.instance.loginRedirect({
							scopes: ['openid', 'offline_access'],
							redirectUri: AppConfig.settings.authenticationconfig.redirectUri
						});
					}
				}
			)
		);
	}
}

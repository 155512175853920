import { Injectable } from '@angular/core';
import { IAppConfig } from './core/interfaces/config';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { IAppMonitor } from './core/interfaces/monitor';

@Injectable()
export class AppConfig {
	static settings: IAppConfig;
	static appMonitor: IAppMonitor;
	private http: HttpClient;
	constructor(private readonly httpHandler: HttpBackend) {
		this.http = new HttpClient(httpHandler);
	}
	load() {
		const cacheBusterParam = (new Date()).getTime();
		const jsonFile = `assets/config.json?nocache=${cacheBusterParam}`;
		// const jsonFile = `assets/config.dev.json?nocache=${cacheBusterParam}`;
		// const jsonFile = `assets/config.test.json?nocache=${cacheBusterParam}`;
		// const jsonFile = `assets/config.staging.json?nocache=${cacheBusterParam}`;
		// const jsonFile = `assets/config.production.json?nocache=${cacheBusterParam}`;
		return new Promise<void>((resolve, reject) => {
			this.http.get(jsonFile).toPromise().then((response: IAppConfig) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				AppConfig.settings = (response as IAppConfig);
				resolve();
			}).catch((response: any) => {
				reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
			});
		});
	}
}
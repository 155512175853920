import {UrlSerializer, UrlTree, DefaultUrlSerializer} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
	parse(url: any): UrlTree {
		const dus = new DefaultUrlSerializer();
		return dus.parse(url);
	}

	serialize(tree: UrlTree): any {
		const dus = new DefaultUrlSerializer();
		let path = dus.serialize(tree);
		const re = /%3D/gi;
		path = path.replace('%3F', '?');
		path = path.replace(re, '=');
		return path;
	}
}

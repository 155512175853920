import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { SecurityService } from './security.service';
import { Observable, Subscription } from 'rxjs';
import { InstanceService } from './instance.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';
import { AreaControlResult } from '../models/area-control-result';

@Injectable()
export class ActivationGuardService  implements OnDestroy {
	HasHADRSubscription: Subscription;
	ShowQueryDataSubscription: Subscription;
	HasPerformanceSubscription: Subscription;
	HasBISubscription: Subscription;

	constructor(
		private httpClient: HttpClient,
		public router: Router,
		private securityService: SecurityService,
		private instanceService: InstanceService
	) { }

	canActivate(next: ActivatedRouteSnapshot):	Observable<boolean> | Promise<boolean> | boolean {
		if(next.url[0].path === 'hadr'){
			this.HasHADRSubscription = this.securityService.UserHasAccessToHADR(next.parent.params.id).subscribe(val => {
				if(val === false){
					this.router.navigate(['/instance/' + next.parent.params.id + '/status']);
					return false;
				}
				return true;
			});
		} else if(next.url[0].path === 'query'){
			this.ShowQueryDataSubscription = this.IsAreaControlled('Performance Query', next.parent.parent.params.id).subscribe(val => {
				if(val.result === true){
					this.router.navigate(['/instance/' + next.parent.parent.params.id + '/performance']);
					return false;
				}
				return true;
			});
		} else if(next.url[0].path === 'performance'){
			this.HasPerformanceSubscription = this.securityService.UserHasAccessToPerf(next.parent.params.id).subscribe(val => {
				if(val === false){
					this.router.navigate(['/instance/' + next.parent.params.id + '/status']);
					return false;
				}
				return true;
			});
		} else if(next.url[0].path === 'backup-history'){
			this.ShowQueryDataSubscription = this.IsAreaControlled('Backup History', next.parent.parent.params.id).subscribe(val => {
				if(val.result === true){
					this.router.navigate(['/instance/' + next.parent.parent.params.id + '/database/status']);
					return false;
				}
				return true;
			});
		} else if(next.url[0].path === 'business-intelligence-executions' || next.url[0].path === 'business-intelligence-execution-history' || next.url[0].path === 'business-intelligence-execution-errors'){
			this.HasBISubscription = this.securityService.UserHasAccessToBI(next.parent.params.id).subscribe(val => {
				if(val === false){
					this.router.navigate(['/client/' + next.parent.params.id + '/environment']);
					return false;
				}
				return true;
			});
		}
		return true;
	}

	public IsAreaControlled(area: string, instanceId: number) {
		let params = new HttpParams().set('area', area);
		params = params.append('instanceId', instanceId === 0 ? '' : instanceId.toString());

		return this.httpClient.get<AreaControlResult>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.areaControl + '/IsAreaControlled/', {params});
	}

	ngOnDestroy() {
		if (this.HasHADRSubscription) {
			this.HasHADRSubscription.unsubscribe();
		}
		if (this.ShowQueryDataSubscription) {
			this.ShowQueryDataSubscription.unsubscribe();
		}
		if (this.HasPerformanceSubscription) {
			this.HasPerformanceSubscription.unsubscribe();
		}
		if (this.HasBISubscription) {
			this.HasBISubscription.unsubscribe();
		}
	}
}

import { formatDate } from '@angular/common';
import cronstrue from 'cronstrue';
import { DateTime } from 'luxon';

export function setDatePropertiesToDate(obj: any): any {
	const keys = Object.keys(obj);
	keys.forEach(key => {
		if (obj[key] != null) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				if (typeof obj[key] === 'object') {
					setDatePropertiesToDate(obj[key]);
				} else {
					// Set if value is of type date, if so make it a date
					const value = obj[key];
					const regex = new RegExp(
						/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i
					);
					if (regex.test(value)) {
						obj[key] = new Date(obj[key]);
					}
				}
			}
		}
	});
	return obj;
}

export function convertStringToDateProperties(items: any[]) {
	if (items) {
		items.map((item: any) => {			
			if(item['optionName'] == 'create_date'){
				const converted = DateTime.fromFormat(item['optionValue'].slice(0, -7), 'yyyy-MM-dd hh:mm:ss.SSS');
				if(converted.isValid){
					item['optionValue'] = converted.toJSDate();
				}	
			} else if(item['optionName'] == 'SysStart'){
				const converted = DateTime.fromFormat(item['optionValue'].slice(0, -4), 'yyyy-MM-dd hh:mm:ss.SSS');
				if(converted.isValid){
					item['optionValue'] = converted.toJSDate();
				}	
			} else if(item['optionName'] == 'SysEnd'){
				const converted = DateTime.fromFormat(item['optionValue'].slice(0, -4), 'yyyy-MM-dd hh:mm:ss.SSS');
				if(converted.isValid){
					item['optionValue'] = converted.toJSDate();
				}	
			}
		});
	}
	return items;
}

export function addFormattedDateProperties(items: any[], locale: string, toFormatPropertyKeys: string[], includeSecs: number ) {
	const extendedItems: any[] = [];
	if (items) {
		items.map((item: any) => {
			let extendedItem: any = new Object();
			extendedItem = Object.assign(extendedItem, item);

			if(includeSecs === 0){
				toFormatPropertyKeys.map(key => {
					extendedItem['formatted_' + key] = item[key] ? formatDate(item[key], 'dd MMM yyyy h:mm a', locale) : null;
				});
			} else if(includeSecs === 1){
				toFormatPropertyKeys.map(key => {
					extendedItem['formatted_' + key] = item[key] ? formatDate(item[key], 'dd MMM yyyy h:mm:ss a', locale) : null;
				});
			} else if(includeSecs === 2){
				toFormatPropertyKeys.map(key => {
					extendedItem['formatted_' + key] = item[key] ? formatDate(item[key], 'dd MMM yyyy h:mm:ss.SSS a', locale) : null;
				});
			}

			extendedItems.push(extendedItem);
		});
	}
	return extendedItems;
}

export function addFormattedBooleanProperties(items: any[], toFormatPropertyKeys: string[] ) {
	const extendedItems: any[] = [];
	if (items) {
		items.map((item: any) => {
			let extendedItem: any = new Object();
			extendedItem = Object.assign(extendedItem, item);

			toFormatPropertyKeys.map(key => {
				extendedItem['formatted_' + key] = item[key] !== null ? (item[key] === true ? 'Yes' : 'No') : '';
			});
			extendedItems.push(extendedItem);
		});
	}
	return extendedItems;
}

export function addFormattedCronProperties(items: any[], toFormatPropertyKeys: string[] ) {
	const extendedItems: any[] = [];
	if (items) {
		items.map((item: any) => {
			let extendedItem: any = new Object();
			extendedItem = Object.assign(extendedItem, item);		
			toFormatPropertyKeys.map(key => {
				item[key] = item[key].replace(',0', ',7');
				extendedItem['formatted_' + key] = item[key] !== null ? cronstrue.toString(item[key], { dayOfWeekStartIndexZero: false }) : '';
			});
			extendedItems.push(extendedItem);
		});
	}
	return extendedItems;
}

export function determineGapDataPlot(element: any, previousPlotData: any, dataKey: string, gapData: any[]): any[] {
	// If current item is null, and it's not the first item being plotted, then we save the gap start data
	if (element[dataKey] === null && !!previousPlotData && previousPlotData[dataKey] !== null) {
		gapData.push(
			{
				x: previousPlotData.eventTimeLocal,
				y: previousPlotData[dataKey]
			}
		);
	}

	// If current item is not null, but previous item is a gap, then we save the gap end data
	if (!!previousPlotData && element[dataKey] !== null && previousPlotData[dataKey] === null) {
		gapData.push(
			{
				x: element.eventTimeLocal,
				y: element[dataKey]
			}
		);
	}

	// If current item is not null, and previous is not null, treat this space as a gap, because we only want to plot the gap data
	if (!!previousPlotData && previousPlotData[dataKey] !== null && element[dataKey] !== null) {
		gapData.push(
			{
				x: element.eventTimeLocal,
				y: null
			}
		);
	}
	return gapData;
}

export function toLuxonUTCDate(date: Date){
	return DateTime.fromMillis(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())).setZone('UTC');
}

export function determineGapDataPlotV2(element: any, previousPlotData: any, dataKey: string, gapData: any[]): any[] {
	// If current item is null, and it's not the first item being plotted, then we save the gap start data
	if (element[dataKey] === null && !!previousPlotData && previousPlotData[dataKey] !== null) {
		gapData.push(
			{
				x: toLuxonUTCDate(previousPlotData.eventTimeLocal),
				y: previousPlotData[dataKey]
			}
		);
	}
	
	// If current item is not null, but previous item is a gap, then we save the gap end data
	if (!!previousPlotData && element[dataKey] !== null && previousPlotData[dataKey] === null) {
		gapData.push(
			{
				x: toLuxonUTCDate(element.eventTimeLocal),
				y: element[dataKey]
			}
		);
	}
	
	// If current item is not null, and previous is not null, treat this space as a gap, because we only want to plot the gap data
	if (!!previousPlotData && previousPlotData[dataKey] !== null && element[dataKey] !== null) {
		gapData.push(
			{
				x: toLuxonUTCDate(element.eventTimeLocal),
				y: null
			}
		);
	}
	return gapData;
}

export const caseInsensitiveSortAccessor = (data: any, sortHeaderId: string): string => {
	if (typeof data[sortHeaderId] === 'string') {
		return data[sortHeaderId].toLocaleLowerCase();
	}
	return data[sortHeaderId];
};

export function exportToCsv(filename: string, columns: string[], rows: Record<string, any>[]) { // ,x: object[]
	if (!rows || !rows.length) {
		return;
	}
	const separator = ',';
	const keys = columns;
	const csvContent =
		keys.join(separator) +
		'\n' +
		rows.map(row => { // row == data object
			return keys.map(k => { // k == each key
				let cell = row[k] === null || row[k] === undefined ? '' : row[k]; // cell == each object property
				cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
				if (cell.search(/(\t|\n)/g) >= 0) {
					cell = cell.replace(/\n/g, '').replace(/\t/g, '');
				}
				if (cell.search(/("|,)/g) >= 0) {
					cell = `"${cell}"`;
				}
				if (cell.search(/(--)+/g) >= 0) {
					cell = '="' + cell + '"';
				}
				return cell;
			}).join(separator);
		}).join('\n');
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
	const link = document.createElement('a');
	if (link.download !== undefined) {
		// Browsers that support HTML5 download attribute
		const url = URL.createObjectURL(blob);
		link.setAttribute('href', url);
		link.setAttribute('download', filename);
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}

<!-- <mat-toolbar class="hazard-background">
	<span class="center">The Portal will undergo scheduled maintenance between StartTime and EndTime today.</span>
</mat-toolbar> -->
<ng-container *ngIf="!inMaintenance">
	<mat-toolbar>
		<a routerLink="/user"><img src="assets/images/logo.svg" alt="DataSentinel logo." title="DataSentinel logo." class="logo"></a>
		<span class="warn" *ngIf="environment">{{ environment }} </span>
		<!-- This fills the remaining space of the current row -->
		<span class="fill-remaining-space"></span>
	
		<ng-toggle *ngIf="displayTimeToggle"
			[(ngModel)]="this.timeCookieValue.zonePreference"	
			[value]="false"
			[width]="125"
			[height]="36"
			[fontSize]="14"
			[margin]="3"
			[color]="{ unchecked: '#ffcc11', checked: '#215298' }"
			[labels]="{ unchecked: 'Server Time', checked: 'Local Time' }"
			[values]="{ unchecked: 'Server', checked: 'Local' }"
			(change)="onTimeToggle()"
			style="margin-right: 5px;"
		>
		</ng-toggle>
	
		<mat-icon color="primary">account_box</mat-icon>
		<span class="user">{{ username }} </span>
	
		<button class="mat-mdc-icon-button" aria-label="Refresh" matTooltip="Refresh">
			<mat-icon color="primary" (click)="onEdit()">settings</mat-icon>
		</button>
	
		<span *ngIf="!authenticated" (click)="login()">
			<button class="mat-mdc-raised-button">Login</button>
		</span>
		<span *ngIf="authenticated" (click)="logout()">
			<button class="mat-mdc-raised-button">Logout</button>
		</span>
	</mat-toolbar>
</ng-container>
<div id="app-container">
	<router-outlet></router-outlet>
</div>
<app-loader></app-loader>

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Injectable({
	providedIn: 'root'
})
export class MonitoringService {
	appInsights: ApplicationInsights;
	constructor() {
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: AppConfig.settings?.appInsights.instrumentationKey,
				enableAutoRouteTracking: true // option to log all route changes
			}
		});
		// this.appInsights.loadAppInsights();
		this.loadCustomTelemetryProperties();
	}

	logPageView(name?: string, url?: string) { // option to call manually
		AppConfig.appMonitor.trackPageView({
			name: name,
			uri: url
		});
	}

	logEvent(name: string, properties?: { [key: string]: any }) {
		AppConfig.appMonitor.trackEvent({ name: name}, properties);
	}

	logMetric(name: string, average: number, properties?: { [key: string]: any }) {
		AppConfig.appMonitor.trackMetric({ name: name, average: average }, properties);
	}

	logException(exception: Error, severityLevel?: number) {
		AppConfig.appMonitor.trackException({ exception: exception, severityLevel: severityLevel });
	}

	logTrace(message: string, properties?: { [key: string]: any }) {
		AppConfig.appMonitor.trackTrace({ message: message}, properties);
	}

	private loadCustomTelemetryProperties()
	{
		AppConfig.appMonitor?.addTelemetryInitializer(envelope =>
		{
			const item = envelope.baseData;
			item.properties = item.properties || {};
			//item.properties['ApplicationPlatform'] = 'WEB';
			//item.properties['A']
			item.properties['ApplicationName'] = 'Gorgon-Dev';
		});
	}
}

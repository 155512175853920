import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForbiddenComponent } from './core/components/forbidden/forbidden.component';
import { MaintenanceComponent } from './core/components/maintenance/maintenance.component';
import { AppContainerComponent } from './core/components/app-container/app-container.component';
import { ReportContainerComponent } from './core/components/report-container/report-container.component';

const routes: Routes = [
	{
		path: '',
		component: AppContainerComponent,
		children: [
			{
				path: '',
				redirectTo: 'user',
				pathMatch: 'full'
			},
			{
				path: 'user',
				loadChildren: () => import('./modules/user/user.module').then(module => module.UserModule)
			},
			{
				path: 'azure',
				loadChildren: () => import('./modules/azure/azure.module').then(module => module.AzureModule)
			},
			{
				path: 'client',
				loadChildren: () => import('./modules/client/client.module').then(module => module.ClientModule)
			},
			{
				path: 'configuration-item',
				loadChildren: () => import('./modules/configuration-item/configuration-item.module').then(module => module.ConfigurationItemModule)
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./modules/dashboard/dashboard.module').then(module => module.DashboardModule)
			},
			{
				path: 'database',
				loadChildren: () => import('./modules/database/database.module').then(module => module.DatabaseModule)
			},
			{
				path: 'instance',
				loadChildren: () => import('./modules/instance/instance.module').then(module => module.InstanceModule)
			},
			{
				path: 'management',
				loadChildren: () => import('./modules/management/management.module').then(module => module.ManagementModule)
			},
			{
				path: 'oracle',
				loadChildren: () => import('./modules/oracle/oracle.module').then(module => module.OracleModule)
			},
			{
				path: 'ticket',
				loadChildren: () => import('./modules/ticket/ticket.module').then(module => module.TicketModule)
			},
			{
				path: 'forbidden',
				component: ForbiddenComponent
			},
			{
				path: 'maintenance',
				component: MaintenanceComponent
			}
		]
	},
	{
		path: 'report',
		component: ReportContainerComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./modules/report/report.module').then(module => module.ReportModule)
			},
			{
				path: 'instance',
				loadChildren: () => import('./modules/instance/instance.module').then(module => module.InstanceModule)
			}
		]
	},
	{ // catch any unfound routes and redirect to home page
		path: '**',
		redirectTo: '',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(
		routes, { 
			enableTracing: false, // <-- Set this to true to debug routing events
		}
	)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackBarComponent } from '../components/error-snack-bar/error-snack-bar.component';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private msalService: MsalService,
		private router: Router,
		private matSnackBar: MatSnackBar
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(err => {
				if (err.status === 401) {
					// auto logout if 401 response returned from api
					if (!this.msalService.instance.getActiveAccount()) {
						console.log('Unauthorised!');
						this.msalService.logout();
					}
					location.reload();
				} else if (err.status === 403) {
					this.router.navigate(['/forbidden'] );
				} else {
					this.matSnackBar.openFromComponent(ErrorSnackBarComponent, {
						data: 'An error has occurred while loading data!',
						duration: 3000,
						verticalPosition: 'bottom',
						horizontalPosition: 'right',
						panelClass: 'notification-alert'
					});
				}

				if (err.error) {
					return throwError(err.error);
				} else {
					return EMPTY;
				}
			}));
	}
}

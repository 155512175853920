import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditPreferenceData } from '../../models/edit-preference-data';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConfig } from 'src/app/app.config';

@Component({
	selector: 'app-dialog-edit-preferences',
	templateUrl: './dialog-edit-preferences.component.html',
	styleUrls: ['./dialog-edit-preferences.component.css']
})
export class DialogEditPreferencesComponent implements OnInit {
	editForm: FormGroup;
	pageSizeOptions: number[] = AppConfig.settings?.pagination.defaultSizeOptions;

	constructor(
		private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<DialogEditPreferencesComponent>,
		@Inject(MAT_DIALOG_DATA) private data: EditPreferenceData
	) { }

	ngOnInit() {
		this.editForm = this.formBuilder.group({
			pagination: ['', Validators.required]
		});
	}

	onSave() {
		if (!this.editForm.invalid) {
			this.dialogRef.close(this.editForm.value);
		}
	}

	onCancel() {
		this.dialogRef.close();
	}
}
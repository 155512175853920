import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { DialogEditPreferencesComponent } from '../dialog-edit-preferences/dialog-edit-preferences.component';
import { EditPreferenceData } from '../../models/edit-preference-data';
import { AppConfig } from 'src/app/app.config';
import { MsalService } from '@azure/msal-angular';
import { DateTime } from 'luxon';
import { NavigationEnd, Router } from '@angular/router';
import { mergeMap, Subscription } from 'rxjs';
import { TimeCookie } from '../../models/time-cookie';
import { SharedService } from '../../services/shared.service';
import { MaintenanceService } from '../../services/maintenance.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-app-container',
	templateUrl: './app-container.component.html',
	styleUrls: ['./app-container.component.css']
})
export class AppContainerComponent implements OnInit, OnDestroy {
	public username: string;
	instanceId: number;
	serviceTypeId: number;
	environment = AppConfig.settings?.name;
	timeCookieValue: TimeCookie;
	displayTimeToggle = false;
	routeSubscription: Subscription;
	sharedSubscription: Subscription;

	maintenanceServiceSubscription: Subscription;
	inMaintenance: boolean;

	constructor(
		private msalService: MsalService,
		private cookieService: CookieService,
		private dialog: MatDialog,
		private router: Router,
		private sharedService: SharedService,
		private maintenanceService: MaintenanceService,
		private location: Location
	) {
		this.routeSubscription = router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const splitted = event.url.split('/', 5);
				if(splitted != undefined){
					splitted.shift();
					// console.log('ROUTE SPLIT');
					// console.log(splitted); //for debugging
					if(splitted[0] == 'instance'){
						this.instanceId = +splitted[1];
						this.serviceTypeId = 1;
					} else if(splitted[0] == 'oracle-instance'){
						if(splitted[2]){
							this.instanceId = +splitted[1];
						} else {
							this.instanceId = +splitted[1].split('?', 5)[0];
						}						
						this.serviceTypeId = 2;
					} else {
						this.instanceId = null;
						this.serviceTypeId = null;
					}
					// console.log(this.instanceId);
					// console.log(this.serviceTypeId);
					//Update if elses as required to support other pages
					if(splitted[2]?.startsWith('status')){
						this.displayTimeToggle = true;
					} else if(splitted[2]?.startsWith('activity')){
						this.displayTimeToggle = true;
					} else if(splitted[2]?.startsWith('instance')){
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'performance' && splitted[3]?.startsWith('cpu')){
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'performance' && splitted[3]?.startsWith('memory')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'performance' && splitted[3]?.startsWith('drive-latency')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'performance' && splitted[3]?.startsWith('sql')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'performance' && splitted[3]?.startsWith('query')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'performance' && splitted[3]?.startsWith('report')) {
						this.displayTimeToggle = true;
					} else if(splitted[0] != 'oracle-instance' && splitted[2] == 'database') {
						this.displayTimeToggle = true;
					} else if(splitted[0] == 'oracle-instance' && splitted[2] != 'database') {
						this.displayTimeToggle = true;
					} else if(splitted[0] == 'database' && splitted[2]?.startsWith('configuration')) {
						this.displayTimeToggle = true;
					} else if(splitted[0] == 'database' && splitted[2]?.startsWith('database-file-history')) {
						this.displayTimeToggle = true;
					} else if(splitted[0] == 'database' && splitted[2]?.startsWith('backup-history')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'security' && splitted[3]?.startsWith('login-failures')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'security' && splitted[3]?.startsWith('insecure-logins')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'security' && splitted[3]?.startsWith('system-admins')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'security' && splitted[3]?.startsWith('encryption-keys')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'security' && splitted[3]?.startsWith('linked-servers')) {
						this.displayTimeToggle = true;
					} else if(splitted[2] == 'security' && splitted[3]?.startsWith('last-logins')) {
						this.displayTimeToggle = true;
					} else {
						this.displayTimeToggle = false;
					}
				}
			}
		});
	}

	ngOnInit() {
		this.username = this.msalService.instance.getActiveAccount().username;
		this.setTimeCookie();
		this.checkForMaintenance();
		setInterval(() => {
			this.setTimeCookie();
			this.checkForMaintenance();
		}, 5000);		
	}

	onEdit() {
		const data: EditPreferenceData = {};
		const dialogRef = this.dialog.open(DialogEditPreferencesComponent,
			{
				data,
				width: '500px'
			});

		dialogRef.afterClosed().subscribe(
			result => {
				if (result) {
					this.cookieService.delete('Pagination');
					this.cookieService.set(
						'Pagination',
						result.pagination,
						{
							expires: DateTime.now().plus({days: 365}).toJSDate(),
							path: '/'
						}
					);
				}
			}
		);
	}

	onTimeToggle(){
		if(this.timeCookieValue.zonePreference == 'Local'){
			//console.log('In AppContainerComponent onTimeToggle zonePreference is Local');
			this.sharedSubscription = this.sharedService.getTimeZone(DateTime.now().zoneName).subscribe(val => {
				this.timeCookieValue.name = val.name;
				this.timeCookieValue.location = DateTime.now().zoneName;
				sessionStorage.removeItem('Time');
				sessionStorage.setItem('Time', JSON.stringify(this.timeCookieValue));
				window.location.reload();
			});
		} else {
			this.sharedSubscription = this.sharedService.getTimeZoneInfo(this.serviceTypeId, this.instanceId).subscribe(val => {
				//console.log('In AppContainerComponent onTimeToggle zonePreference is Server');
				this.timeCookieValue.name = val.name;
				this.timeCookieValue.location = val.location;
				sessionStorage.removeItem('Time');
				sessionStorage.setItem('Time', JSON.stringify(this.timeCookieValue));
				window.location.reload();
			});
		}
	}

	setTimeCookie(){
		//console.log('In AppContainerComponent setTimeCookie');
		if(sessionStorage.getItem('Time') != null){ //Existing Cookie
			//console.log('Existing Cookie');
			if(this.timeCookieValue == null){ //Local Variable Not Set
				//console.log('Local Variable Not Set');
				this.timeCookieValue = JSON.parse(sessionStorage.getItem('Time'));
			}
			if(this.instanceId != undefined && this.timeCookieValue.instance != this.instanceId){ //Instance Has Changed
				//console.log('Instance Has Changed');
				if(JSON.parse(sessionStorage.getItem('Time')).zonePreference == 'Local'){
					//console.log('zonePreference is Local');
					this.sharedSubscription = this.sharedService.getTimeZone(DateTime.now().zoneName).subscribe(val => {
						//console.log(val);
						this.timeCookieValue = { zonePreference: JSON.parse(sessionStorage.getItem('Time')).zonePreference, name: val.name, location: DateTime.now().zoneName, instance: this.instanceId };
						sessionStorage.removeItem('Time');
						sessionStorage.setItem('Time', JSON.stringify(this.timeCookieValue));
					});
				} else {
					//console.log('zonePreference is Server');
					this.sharedSubscription = this.sharedService.getTimeZoneInfo(this.serviceTypeId, this.instanceId).subscribe(val => {
						//console.log(val);
						this.timeCookieValue = { zonePreference: this.timeCookieValue.zonePreference, name: val.name, location: val.location, instance: this.instanceId };
						sessionStorage.removeItem('Time');
						sessionStorage.setItem('Time', JSON.stringify(this.timeCookieValue));
					});
				}
			}
		} else { //No Existing Cookie
			//console.log('No Existing Cookie');
			if(this.instanceId){
				//console.log('Has Instance');
				this.sharedSubscription = this.sharedService.getTimeZoneInfo(this.serviceTypeId, this.instanceId).subscribe(val => {
					//console.log(val);
					this.timeCookieValue = { zonePreference: 'Local', name: val.name, location: val.location, instance: this.instanceId };
					sessionStorage.setItem('Time', JSON.stringify(this.timeCookieValue));
				});	
			} else {
				//console.log('Has Not Instance');
				this.sharedSubscription = this.sharedService.getTimeZone(DateTime.now().zoneName).subscribe(val => {
					//console.log(val);
					this.timeCookieValue = { zonePreference: 'Local', name: val.name, location: DateTime.now().zoneName, instance: null };
					sessionStorage.setItem('Time', JSON.stringify(this.timeCookieValue));
				});
			}
		}	
	}

	checkForMaintenance(){
		this.maintenanceServiceSubscription = this.maintenanceService.getMaintenanceStatus()?.subscribe( (response: boolean) => {
			// console.log('Maintenance status: ' + response);
			if (response) {
				// console.log('Maintenance mode is on');
				this.inMaintenance = true;
				this.router.navigate(['/maintenance']);
			} else {
				// console.log('Maintenance mode is off');
				this.inMaintenance = false;
				// console.log(this.location.path());
				if(this.location.path() == '/maintenance'){
					this.router.navigate(['/user']);
				}				
			}
		});
	}

	login() {
		const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
		if (!isIE) {
			this.msalService.instance.loginRedirect({
				scopes: ['user.read', 'openid', 'profile']
			});
		} else {
			this.msalService.instance.loginPopup({
				scopes: ['user.read', 'openid', 'profile']
			});
		}
	}

	logout() {
		this.msalService.instance.logout();
	}

	get authenticated(): boolean {
		return this.msalService.instance.getActiveAccount() ? true : false; // .getAccount() ? true : false;
	}

	ngOnDestroy() {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
		if (this.sharedSubscription) {
			this.sharedSubscription.unsubscribe();
		}
		if (this.maintenanceServiceSubscription) {
			this.maintenanceServiceSubscription.unsubscribe();
		}
	}
}
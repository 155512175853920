import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
	selector: 'app-success-snack-bar',
	templateUrl: './success-snack-bar.component.html',
	styleUrls: ['./success-snack-bar.component.css']
})
export class SuccessSnackBarComponent implements OnInit {

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { }

	ngOnInit(): void {
	}

}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { setDatePropertiesToDate } from '../utilities/utility-methods';

@Injectable()
export class DatePropertiesInterceptor implements HttpInterceptor {

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					const body = event.body;
					setDatePropertiesToDate(body);
				}
			})
		);
	}
}

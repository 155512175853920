<style>
	.outer-container {
		height: calc(100vh - 200px);;
		padding: 100px;
		background-color: #f1f1f1;
		overflow-y: hidden !important;
	}
	.container {
		background: white;
		padding: 40px;
		border-radius: 10px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		display: block;
		text-align: left;
		width: 600px;
		margin: auto;
	}
	h1 {
		font-size: 36px;
		margin-bottom: 20px;
		text-align: center;
	}
	p {
		font-size: 16px;
		line-height: 1.5;
		margin: 20px 0;
	}
	a {
		color: #007bff;
		text-decoration: none;
	}
	a:hover {
		text-decoration: underline;
	}
	img.logo {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 50%;
		max-width: 250px;
		margin: auto;
	}
</style>
<div class="outer-container">
	<div class="container">
		<a href="https://datasentinel.co.nz/"><img src="assets/images/logo.svg" alt="DataSentinel logo." title="DataSentinel logo." class="logo"></a>
		<h1>We'll be back soon!</h1>
		<div>
			<p>
				Sorry for the inconvenience but we're performing some maintenance at the moment.<br>
				If you require assistance, please don't hesitate to contact us but giving us a call on <a href="tel:0800 377 877">0800 377 877</a> or visit
				our <a href="https://datasentinel.co.nz/contact" target="_blank">website</a> for more options, otherwise we'll be back online shortly!
			</p>
			<p>- Kind regards, the team at <strong>Data</strong>Sentinel</p>
		</div>
	</div>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { TimeZoneInfo } from '../models/time-zone-info';

@Injectable({
	providedIn: 'root'
})
export class SharedService {
	constructor(private httpClient: HttpClient) { }

	public getTimeZone(location: string) {
		const params = new HttpParams().set('Location', location);
		return this.httpClient.get<TimeZoneInfo>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.shared + '/GetTimeZone', {params});
	}

	public getTimeZoneInfo(serviceTypeId: number, instanceId: number) {
		let params = new HttpParams().set('serviceTypeID', serviceTypeId.toString());
		params = params.append('InstanceId', instanceId.toString());
		return this.httpClient.get<TimeZoneInfo>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.shared + '/GetTimeZoneInfo', {params});
	}

	public customerHasSLADefaults(customerId: number, serviceTypeId: number) {
		const params = new HttpParams()
			.set('customerId', customerId.toString())
			.set('serviceTypeId', serviceTypeId.toString());
		return this.httpClient.get<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.shared + '/CustomerHasSLADefaults', {params});
	}
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AppConfig } from './app.config';
import { MonitoringService } from './core/services/monitoring.service';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
	private readonly _destroying$ = new Subject<void>();

	constructor
	(
		private msalService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private monitoringService: MonitoringService
	) {}

	ngOnInit() {
		this.msalBroadcastService.inProgress$
			.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None), takeUntil(this._destroying$))
			.subscribe(async () => {
				if (!this.authenticated) {
					await this.logIn();
				}
			});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS)
			).subscribe((result: EventMessage) => {
				const payload = result.payload as AuthenticationResult;
				this.msalService.instance.setActiveAccount(payload.account);
			});

		// this.setAppInsights();
		this.monitoringService.logEvent('Message', {'App Loaded': 'App Loaded.'});
	}

	async logIn() {
		await this.msalService.instance.loginRedirect({
			// scopes: ['user.read', 'openid', 'profile'],
			scopes: ['openid', 'offline_access'],
			redirectUri: AppConfig.settings.authenticationconfig.redirectUri
		});
	}

	get authenticated(): boolean {
		return this.msalService.instance.getActiveAccount() ? true : false;
	}

	// private setAppInsights()
	// {
	//	 try
	//	 {
	//		 const s = this.doc.createElement('script');
	//		 s.type = 'text/javascript';
	//		 s.innerHTML = 'var appInsights=window.appInsights||function(a){ function b(a){c[a]=function(){var b=arguments;c.queue.push(function(){c[a].apply(c,b)})}}var c={config:a},d=document,e=window;setTimeout(function(){var b=d.createElement("script");b.src=a.url||"https://az416426.vo.msecnd.net/scripts/a/ai.0.js",d.getElementsByTagName("script")[0].parentNode.appendChild(b)});try{c.cookie=d.cookie}catch(a){}c.queue=[];for(var f=["Event","Exception","Metric","PageView","Trace","Dependency"];f.length;)b("track"+f.pop());if(b("setAuthenticatedUserContext"),b("clearAuthenticatedUserContext"),b("startTrackEvent"),b("stopTrackEvent"),b("startTrackPage"),b("stopTrackPage"),b("flush"),!a.disableExceptionTracking){f="onerror",b("_"+f);var g=e[f];e[f]=function(a,b,d,e,h){var i=g&&g(a,b,d,e,h);return!0!==i&&c["_"+f](a,b,d,e,h),i}}return c }({ instrumentationKey:' + environment.appInsights.instrumentationKey + ' }); window.appInsights=appInsights,appInsights.queue&&0===appInsights.queue.length&&appInsights.trackPageView();';
	//		 const head = this.doc.getElementsByTagName('head')[0];
	//		 head.appendChild(s);
	//	 }
	//	 catch
	//	 {
	//	 }
	// }

	ngOnDestroy(): void {
		this._destroying$.next(null);
		this._destroying$.complete();
	}
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';

@Injectable({
	providedIn: 'root'
})
export class SecurityService {

	constructor(private httpClient: HttpClient) { }

	public UserHasAccessToHADR(instanceId: number) {
		return this.httpClient.get<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.security + '/UserHasAccessToHADR/' + instanceId.toString());
	}

	public UserHasAccessToPerf(instanceId: number) {
		return this.httpClient.get<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.security + '/UserHasAccessToPerf/' + instanceId.toString());
	}

	public UserHasAccessToManagement() {
		return this.httpClient.get<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.security + '/UserHasAccessToManagement');
	}

	public UserHasAccessToManagementAdmin() {
		return this.httpClient.get<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.security + '/UserHasAccessToManagementAdmin');
	}

	public UserHasAccessToBI(customerId: number) {
		return this.httpClient.get<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.security + '/UserHasAccessToBI/' + customerId.toString());
	}

	public UserHasAccessToAzure(customerId: number) {
		return this.httpClient.get<boolean>(AppConfig.settings?.url.baseApiUrl + AppConfig.settings?.url.api.security + '/UserHasAccessToAzure/' + customerId.toString());
	}
}

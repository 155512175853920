import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
// import { NoopAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, UrlSerializer } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './core/components/loader/loader.component';
import { LoaderService } from './core/services/loader.service';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { ForbiddenComponent } from './core/components/forbidden/forbidden.component';
import { DatePropertiesInterceptor } from './core/interceptors/date-properties.interceptor';
import { ErrorSnackBarComponent } from './core/components/error-snack-bar/error-snack-bar.component';
import { AppContainerComponent } from './core/components/app-container/app-container.component';
import { ReportContainerComponent } from './core/components/report-container/report-container.component';
import { InfoSnackBarComponent } from './core/components/info-snack-bar/info-snack-bar.component';
import { CustomUrlSerializer } from './core/utilities/url-serializer';
import { DialogEditPreferencesComponent } from './core/components/dialog-edit-preferences/dialog-edit-preferences.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivationGuardService } from './core/services/activation-guard.service';
import { AppConfig } from './app.config';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { MsalModule, MsalService, MSAL_INSTANCE, MsalBroadcastService, MsalRedirectComponent } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { NgxEditorModule } from 'ngx-editor';
import { SuccessSnackBarComponent } from './core/components/success-snack-bar/success-snack-bar.component';
import { MsalInterceptor } from './core/interceptors/msal-interceptor';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { NgToggleModule } from 'ng-toggle-button';
// import { LogLevel } from 'msal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaintenanceComponent } from './core/components/maintenance/maintenance.component';
// import { MaintenanceService } from './core/services/maintenance.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function MSALConfigFactory(): IPublicClientApplication	{
	return new PublicClientApplication({
		auth: {
			clientId: AppConfig.settings?.authenticationconfig.gorgonClientId,
			// authority: 'https://login.microsoftonline.com/' + AppConfig.settings.authenticationconfig.tenant,
			// authority: 'https://' + '{azureADB2CHostname}' + '/tfp/' + '{tenant}' + '/' + '{policyName}',
			authority: 'https://' + AppConfig.settings?.authenticationconfig.tenant + '.b2clogin.com/' + AppConfig.settings?.authenticationconfig.tenant + '.onmicrosoft.com/' + AppConfig.settings?.authenticationconfig.signInPolicy,
			knownAuthorities: [AppConfig.settings?.authenticationconfig.tenant + '.b2clogin.com'],
			// validateAuthority: true,
			redirectUri: AppConfig.settings?.authenticationconfig.redirectUri,
			postLogoutRedirectUri: AppConfig.settings?.authenticationconfig.redirectUri,
			navigateToLoginRequestUrl: true
		},
		cache: {
			cacheLocation: 'sessionStorage',
			storeAuthStateInCookie: false,
		}
	});
}

export function initializeApp(appConfig: AppConfig) {
	const promise = appConfig.load().then(() => {
		if (AppConfig.settings) {
			const appInsights = new ApplicationInsights({
				config: {
					instrumentationKey: AppConfig.settings.appInsights.instrumentationKey,
					enableAutoRouteTracking: true // option to log all route changes
				}
			});
			appInsights.loadAppInsights();
			appInsights.trackPageView();
			AppConfig.appMonitor = appInsights;
		}
	});
	return () => promise;
}

@NgModule({
	declarations: [
		AppComponent,
		LoaderComponent,
		ForbiddenComponent,
		ErrorSnackBarComponent,
		AppContainerComponent,
		ReportContainerComponent,
		InfoSnackBarComponent,
		DialogEditPreferencesComponent,
		SuccessSnackBarComponent,
		MaintenanceComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		MatButtonModule,
		MatToolbarModule,
		MatProgressBarModule,
		BrowserAnimationsModule,
		// NoopAnimationsModule,
		MatIconModule,
		MatCardModule,
		MatSnackBarModule,
		MatDialogModule,
		MatSelectModule,
		ReactiveFormsModule,
		MsalModule,
		NgxEditorModule,
		NgToggleModule,
		FormsModule,
		NgxSpinnerModule
	],
	providers: [
		ActivationGuardService,
		LoaderService,
		AppConfig,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [AppConfig],
			multi: true
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALConfigFactory,
			deps: [AppConfig]
		},
		MsalService,
		MsalBroadcastService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			deps: [MsalService],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			deps: [MsalService, Router, MatSnackBar],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DatePropertiesInterceptor,
			multi: true
		},
		{ provide: LOCALE_ID, useValue: 'en-NZ' },
		{ provide: UrlSerializer, useClass: CustomUrlSerializer }
	],
	bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(fasStar, farStar);
	}
}